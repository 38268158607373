/*
 * @Description:
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-01-11 13:13:13
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-04-24 17:40:07
 */
import axios from "axios";
import Vue from "vue";
import { Toast } from "vant";
const service = axios.create({
	baseURL: process.env.VUE_APP_URL,
});
service.interceptors.request.use(
	(config) => {
		if (localStorage.getItem("token")) {
			config.headers.common["Authorization"] = localStorage.getItem("token");
			return config;
		} else {
			return config;
		}
	},
	(error) => {
		window.location.reload();
		return Promise.reject(error);
	}
);
service.interceptors.response.use(
	(response) => {
		const res = response;
		// -51003 token过期
		// alert(res.data.Result)
		if (res.data.Result === -51003) {
			Toast.fail("登录过期，请重新登录");
			if (localStorage.getItem("token")) {
				localStorage.removeItem("token");
				localStorage.removeItem("user_type");
			}
			window.location.href = "/";
			return res;
		} else {
			return res;
		}
	},
	(error) => {
		Toast.fail("状态错误");
		console.log("err" + error);
		return Promise.reject(error);
	}
);
Vue.use(Toast);
export default service;
